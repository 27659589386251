/* Auxiliar functions 
 ----------------------------------------------- */
let badAnswer = (input: string, empty: boolean,
  match: string, length: boolean, label: string,
  zero: string, num: number) => {
  let selector = <HTMLElement> document.querySelector('#alert-' + input);
  let inputSel = <HTMLElement> document.querySelector('#' + input);

  selector.innerHTML = '';
  inputSel.style.border = '1px solid #E60000';

  // Input Empty
  if (empty) {
    selector.innerHTML = '<p class="text-warning" id="alert-msg">Por favor ingresa '
        + label + '</p>';
    return false;
  }
  // String extension
  if (length) {
    if (input === 'telefono') {
      selector.innerHTML = '<p class="text-warning" id="alert-msg">'
          + 'Ingresa tu número telefónico a 10 dígitos.</p>';
    } else if (input === 'cuanto') {
      selector.innerHTML = '<p class="text-warning" id="alert-msg">'
          + 'Por favor ingresa una cantidad igual o mayor a $35,000</p>';
    } else if (input === 'nombre') {
       selector.innerHTML = '<p class="text-warning" id="alert-msg">'
          + 'Ingresa tu nombre completo</p>';
    } 
    return false;
  }
  // Match (Letters, Email, Numbers})
  switch (match) {
    case 'text':
      selector.innerHTML = '<p class="text-warning" id="alert-msg">'
        + 'Sólo se permiten letras.<p>'
      return false;
    case 'email':
       selector.innerHTML = '<p class="text-warning" id="alert-msg">'
        + 'Ingresa una dirección válida, ejemplo: nombre@mail.com<p>'
      return false;
    case 'number':
      if (zero === 'zero') {
        selector.innerHTML = '<p class="text-warning" id="alert-msg">'
        + 'Este campo no puede comenzar con "0"<p>'
      } else {
        selector.innerHTML = '<p class="text-warning" id="alert-msg">'
        + 'Únicamente números.<p>'
      }
      return false;
      default:
        console.log('default');
      break;
  }
};

let goodAnswer = (input: string) => {
  let selector = <HTMLElement> document.querySelector('#alert-' + input);
  let inputSel = <HTMLElement> document.querySelector('#' + input);
  selector.innerHTML = '';
  inputSel.style.border = '1px solid #77BC1F';
};

export function validateText(input: string, answer: string, label: string): boolean  {
  if (answer === '' || answer === undefined || answer === null) {
    badAnswer(input, true, 'text', false, label, '', 0);
    return false;
  }
  if (!answer.match(/^[a-z ñáéíóú A-Z ÑÁÉÍÓÚ]+$/)) {
    badAnswer(input, false, 'text', false, label, '', 0);
    return false;
  } else {
    if (input === 'nombre' && answer.length < 3) {
      badAnswer(input, false, 'text', true, label, '', 3);
    } else {
      goodAnswer(input);
      return true;
    }
  }
};

export function validateEmail(input: string, answer: string, label: string): boolean {
  if (answer === '' || answer === undefined || answer === null) {
    badAnswer(input, true, 'email', false, label, '', 0);
    return false;
  }
  if (!answer.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    badAnswer(input, false, 'email', false, label, '', 0);
    return false;
  } else {
    goodAnswer(input);
    return true;
  }
};

export function validateNumber(input: string, answer: string,
  label: string, zero: boolean, num: number): boolean {
  if (answer === '' || answer === undefined || answer === null) {
    badAnswer(input, true, 'number', false, label, '', num);
    return false;
  }
  if (zero) {
    if (!answer.match('^([0-9][0-9]*)$')) {
      badAnswer(input, false, 'number', false, label, '', num);
      return false;
    }
  } else {
    if (!answer.match('^(0|[1-9][0-9]*)$')) {
      if (answer.substring(0, 1) === '0') {
        badAnswer(input, false, 'number', false, label, 'zero', num);
      } else {
        badAnswer(input, false, 'number', false, label, '', num);
      }
      return false;
    }
  }
  if (answer.length < num) {
    

    console.log('menor');


    badAnswer(input, false, 'number', true, label, '', num);
    return false;
  } else {
    if (input === 'cuanto') {
      let selector = <HTMLElement> document.querySelector('#alert-' + input);
      let inputSel = <HTMLElement> document.querySelector('#' + input);
      if (parseInt(answer) < 50000) {
        selector.innerHTML = '<p class="text-warning" id="alert-msg">'
          + 'La cantidad mínima es de $50,000 pesos.</p>';
        inputSel.style.border = '1px solid #E60000';
      } else if (parseInt(answer) > 1000000) {
        selector.innerHTML = '<p class="text-warning" id="alert-msg">'
          + 'La cantidad máxima es de $1,000,000 pesos.</p>';
        inputSel.style.border = '1px solid #E60000';
      } else {
        goodAnswer(input);
        return true;
      }
      return false;
    }
    goodAnswer(input);
    return true;
  }
};
export function validateSelect(value: string, input: string) {
  let selector = <HTMLElement> document.querySelector('#alert-' + input);
  let inputSel = <HTMLElement> document.querySelector('#' + input);

  if (value === '' || value === null || value === undefined) {
    selector.innerHTML = '<p class="text-warning" id="alert-msg">'
      + 'Elige una opción de la lista. Este campo es requerido.</p>';
    inputSel.style.border = '1px solid #E60000';
    return false;
  } else {
    selector.innerHTML = '';
    inputSel.style.color = '#494949';
    inputSel.style.border = '1px solid #77BC1F';
    return true;
  }
}

export function validateCheck(num: number, input: string): boolean {
  let selector = <HTMLElement> document.querySelector('#alert-' + input);
  let inputSel = <HTMLElement> document.querySelector('#' + input);
 
  if (num === 0) {
    selector.innerHTML = '<p class="text-warning" id="alert-msg">'
      + 'Para continuar debes aceptar términos y condiciones.</p>';
    return false;
  } else {
    selector.innerHTML = '';
   
    return true;
  }
};
