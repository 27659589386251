// Se obtiene el estado de acuerdo a los dos primeros dígitos del CP
export function estado(cp: string) {
  let edo: string;
  switch (cp.substr(0, 2)) {
    case "01":
    case '02':
    case '03':
    case '04':
    case '05':
    case '06':
    case '07':
    case '08':
    case '09':
    case '10':
    case '11':
    case '12':
    case '13':
    case '14':
    case '15':
    case '16':
      edo = 'Distrito Federal';
      break;
    case '20':
      edo = 'Aguascalientes';
      break;
    case '21':
    case '22':
      edo = 'Baja California';
      break;
    case '23':
      edo = 'Baja California Sur';
      break;
    case '24':
      edo = 'Campeche';
      break;
    case '25':
    case '26':
    case '27':
      edo = 'Coahuila';
      break;
    case '28':
    case '35':
      edo = 'Colima';
      break;
    case '29':
    case '30':
      edo = 'Chiapas';
      break;
    case '31':
    case '32':
    case '33':
      edo = 'Chihuahua';
      break;
    case '34':
      edo = 'Durango';
      break;
    case '36':
    case '37':
    case '38':
    case '40':
    case '41':
      edo = 'Guanajuato';
      break;
    case '39':
      edo = 'Guerrero';
      break;
    case '42':
    case '43':
      edo = 'Hidalgo';
      break;
    case '44':
    case '45':
    case '46':
    case '47':
    case '48':
    case '49':
      edo = 'Jalisco';
      break;
    case '50':
    case '51':
    case '52':
    case '53':
    case '54':
    case '55':
    case '56':
    case '57':
      edo = 'Estado de México';
      break;
    case '58':
    case '59':
    case '60':
    case '61':
      edo = 'Michoacán';
      break;
    case '62':
      edo = 'Morelos';
      break;
    case '63':
      edo = 'Nayarit';
      break;
    case '64':
    case '65':
    case '66':
    case '67':
      edo = 'Nuevo León';
      break;
    case '68':
    case '69':
    case '70':
    case '71':
      edo = 'Oaxaca';
      break;
    case '72':
    case '73':
    case '74':
    case '75':
      edo = 'Puebla';
      break;
    case '76':
      edo = 'Querétaro';
      break;
    case '77':
      edo = 'Quintana Roo';
      break;
    case '78':
    case '79':
      edo = 'San Luis Potosí';
      break;
    case '80':
    case '81':
    case '82':
      edo = 'Sinaloa';
      break;
    case '83':
    case '84':
    case '85':
      edo = 'Sonora';
      break;
    case '86':
      edo = 'Tabasco';
      break;
    case '87':
    case '88':
    case '89':
      edo = 'Tamaulipas';
      break;
    case '90':
      edo = 'Tlaxcala';
      break;
    case '91':
    case '92':
    case '93':
    case '94':
    case '95':
    case '96':
      edo = 'Veracruz';
      break;
    case '97':
      edo = 'Yucatán';
      break;
    case '98':
    case '99':
      edo = 'Zacatecas';
      break;
    }
  return edo;
}
