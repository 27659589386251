// Se agregan opciones a un Select
export class OptionsSelect {
  public selector: HTMLElement;
  public option: HTMLElement;

  constructor (element: HTMLElement, text: string) {
    this.selector = element;
    this.option = document.createElement('option');
    this.option.textContent = text;
    this.option.className = 'options';
    this.selector.appendChild(this.option);
  }
}
