/// <reference path="../typings/globals/greensock/index.d.ts" />

import { User } from "./classes/mobileDetect";
import { addEventClass } from "./classes/addEventClass";
import { validateText, validateEmail, validateNumber, validateCheck } from "./classes/validation";
import { OptionsSelect } from "./classes/options";
import { estado } from "./classes/estado";

'use strict';
class Main {
  constructor() {

    document.addEventListener("DOMContentLoaded", function(event) {
      
      /* Vars
      ---------------------------------------------------------------------------------- */
      // Sitio
      let widthScreen = window.innerWidth;
      let heightScreen = window.innerHeight;
      let i: number;
      let posScroll: number;
      let elementPos: number;
      let diffNav: any;
      let wrapperSection = document.getElementsByClassName('wrapper-section');

      // Menu
      let iconMenu = document.getElementById('icon-menu');
      let closeMenu = document.getElementById('close-menu-mobile');
      let menuMobile = document.getElementById('content-nav-mobile')
      let phoneMobile = document.getElementById('phone-mobile');
      let contentMenuMobile = document.getElementById('content-nav-mobile')
      let submenuMobile = document.getElementById('submenu-mobile');
      let submenuMobileBoolean = false;

      let historiasPos: number;
      let funcionaPos: number;
      let requisitosPos: number;
      let somosPos: number;
      let formPos: number;

      // Image Hero
      let containerImageHero = document.getElementById('video-background');
      let countImg = 1;

      let testimony1 = document.createElement('div');
      let testimony2 = document.createElement('div');
      let testimony3 = document.createElement('div');
      let testimony4 = document.createElement('div');
      let testimony5 = document.createElement('div');
      let testimony6 = document.createElement('div');
      let testimony7 = document.createElement('div');
      let frontHeroe = document.createElement('div');

      // Slider
      let posSliderInit: number;
      let posSliderEnd: number;
      let posSliderDiff: number;
      let containerImg = document.getElementById('container-images');
      let actualImage = 1;
      let totalImages = document.getElementsByClassName('item-photo');
      let sliderActive = false;

      // Historias
      let storiesBoolean:boolean;
      let images = ['rodolfo', 'maria', 'araceli', 'fermin', 'benjamin',
        'lourdes', 'guillermina'];
      let posStr: number = 0;
      let imageStories = document.getElementById('image-stories');
      let titleStories = document.getElementById('title-stories');
      let subtitleStories = document.getElementById('subtitle-stories');
      let textStories = document.getElementById('text-stories');
      let reinforcementStories = document.getElementById('reinforcement-stories');
      let titleVideo = document.getElementById('title-video-stories');
      let textVideo = document.getElementById('text-video-stories');
      let containerVideo = document.getElementById('container-video');
      let frontVideo = document.getElementById('img-front-video');
      let wrapperStories = document.getElementById('wrapper-story');
      let quote = document.getElementById('text-quote');

      let stories = ['RODOLFO', 'MARÍA DEL ROSARIO', 'ARACELI', 'FERMÍN', 'BENJAMÍN',
        'MARÍA DE LOURDES', 'GUILLERMINA']
      let subtitleStoriesContent = ['DESEMPLEO', 'ENFERMEDAD', 'DESEMPLEO',
        'EMERGENCIA FAMILIAR', 'LA EDUCACIÓN DE LOS HIJOS', 'MALA ADMINISTRACIÓN',
        'DAR LO MEJOR A SUS HIJOS'
      ];
      let textStoriesContent = [
        '<p>Rodolfo debía 7 tarjetas que en total sumaban $231,311 pesos.  En Resuelve' +
          ' tu Deuda encontró el apoyo profesional que necesitaba para liquidarlas y la' +
          ' asesoría financiera que lo ayudó a adquirir hábitos financieros saludables' +
          ' que lo acompañarán el resto de su vida.</p>',
        '<p>María del Rosario ingresó a Resuelve tu Deuda debiendo $175,792 pesos.' +
          ' Gracias al descuento que obtuvimos para ella, liquidó pagando sólo $60,822.' +
          ' En poco más de un año pudo terminar con este problema que no le permitía' +
          ' salir adelante.</p>',
        '<p>La deuda de Araceli llegó hasta los $223,586. Los pagos mínimos que le' +
          ' exigían las tarjetas sumaban casi el 60% de sus ingresos. Al quedar sin' +
          ' trabajo, sus finanzas se vinieron abajo.</p>',
        '<p>Fermín llegó a deber $65,396. En Resuelve tu Deuda liquidó pagando' +
          ' $18,397 pesos gracias a un plan de liquidación hecho a su medida.</p>',
        '<p>Cuando Benjamín ingresó al programa Resuelve tu Deuda, debía $224,307.' +
          'Gracias a nuestras negociaciones, pudo liquidar pagando sólo $68,933.</p>',
        '<p>María de Lourdes debía $513,355 pesos. Con sus ingresos le resultaba' +
          ' imposible cubrir esta deuda de más de medio millón. Gracias al descuento' +
          ' obtenido por Resuelve tu Deuda, podrá liquidar pagando hasta 70% menos.</p>',
        '<p>La deuda de Guillermina llegó hasta los $153,971. Gracias a Resuelve tu' +
          ' Deuda pudo liquidarla con sólo $41,573 pesos, <span>sin necesitar un' +
          ' préstamo más.<span></p>'
      ];
      let reinforcementStoriesContent = [
        'Si pudimos ayudar a Rodolfo, podemos ayudarte.',
        'Si pudimos ayudar a María, podemos ayudarte.',
        'Si pudimos ayudar a Araceli, podemos ayudarte.',
        'Si pudimos ayudar a Fermín, podemos ayudarte.',
        'Si pudimos ayudar a Benjamín, podemos ayudarte.',
        'Si pudimos ayudar a María de Lourdes, podemos ayudarte.',
        'Si pudimos ayudar a Guillermina, podemos ayudarte.'
      ]
      let titleVideoContent = [
        'LA HISTORIA DE RODOLFO',
        'LA HISTORIA DE MARÍA',
        'LA HISTORIA DE ARACELI',
        'LA HISTORIA DE FERMÍN',
        'LA HISTORIA DE BENJAMÍN',
        'LA HISTORIA DE MARÍA DE LOURDES',
        'LA HISTORIA DE GUILLERMINA'
      ];
      let textVideoContent = [
        '<p>Tras ser despedido injustamente, Rodolfo comenzó a utilizar sus tarjetas' +
          ' para solventar los gastos del día a día. Él pensó que con el dinero de' +
          ' su liquidación podría saldar la deuda, pero la empresa para la que' +
          ' trabajaba se declaró en quiebra, por lo que nunca recibió un peso.</p>',
        '<p>María decidió construir un cuarto tipo hospital para sus papás. A pesar' +
          '  de que en su familia todos estuvieron de acuerdo, al final la dejaron' +
          ' sola con la deuda. Solicitó préstamos en varios lugares, pero llegó el' +
          ' momento en el que ya no tuvo dinero para pagar.</p>',
        '<p>Al perder su principal fuente de ingresos, Araceli recurrió a las' +
          ' tarjetas para intentar mantener el equilibrio financiero. Los pagos' +
          ' se salieron de control, las llamadas de cobranza no paraban y fue' +
          ' cuando solicitó ayuda a Resuelve tu Deuda.</p>',
        '<p>La deuda de Fermín comenzó cuando su hija le pidió apoyo económico.' +
          ' Al estar desempleado y no tener un ingreso, comenzó a utilizar las' +
          ' tarjetas de crédito hasta saturarlas.</p>',
        '<p>La determinación de Benjamín por brindar una educación de calidad' +
          ' a sus hijos lo llevó a un desequilibrio financiero que terminó' +
          ' en una deuda impagable.</p>',
        '<p>Al verse frente a una repentina pérdida de ingresos, María de' +
          ' Lourdes comenzó a utilizar sus tarjetas para mantener el estilo' +
          ' de vida al que estaba acostumbrada. La bola de nieve terminó' +
          ' en una deuda de medio millón.</p>',
        '<p>Después de su divorcio, Guillermina solicitó un préstamo para' +
          ' construir una casa para ella y sus hijos. Cuando los imprevistos' +
          ' comenzaron a ocurrir, tramitó nuevos préstamos que terminaron' +
          ' por desequilibrar sus finanzas.</p>'
      ];
      let quoteContent = [
        'Llegué a Resuelve tu Deuda sintiéndome angustiado por no saber' +
          ' cómo acabar con mi problema de deudas. Ahí conocí el lado opuesto' +
          ' de la moneda, me sentí en un mar de tranquilidad',
        'En internet encontré a Resuelve tu Deuda y acudí a ellos. Desde' +
          ' la primera llamada me tranquilizaron y me explicaron cómo harían' +
          ' para ayudarme con mi deuda. Con su ayuda ya no tengo ninguna' +
          ' deuda pendiente, por fin me siento en paz',
        'Yo confié en Resuelve tu Deuda desde el primer momento, ellos me' +
          ' orientaron y me tranquilizaron. Con su ayuda y mi constancia,' +
          ' terminé con mi problema de deuda antes de lo que pensaba.',
        'Una hija fue la que me animó a entrar a Resuelve tu Deuda. Cuando' +
          ' yo llegué con ellos, me hicieron un plan según mis posibilidades' +
          ' que me ayudaría a liquidar todas mis deudas. Ya terminé de pagar,' +
          ' gracias Resuelve tu Deuda salí adelante',
        'La primera vez que supe de Resuelve tu Deuda me surgieron muchas' +
          ' dudas. Llamé por teléfono y ahí quedé convencido del programa' +
          ' y decidí confiar en ellos. Fue la mejor decisión que pude' +
          ' tomar. Para mí, ya no existen las deudas.',
        'Un sobrino me acompañó a Resuelve tu Deuda. Ahí nos explicaron' +
          ' perfectamente cómo me ayudarían, y ese mismo día me inscribí en' +
          ' el programa. Desde que entré a Resuelve tu Deuda estoy tranquila,' +
          ' no tengo presiones y ¡ah qué bonito duermo!',
        'Supe de Resuelve tu Deuda por recomendación de una amiga. Llamé' +
          ' a la sucursal, agendé una cita y decidí entrar al programa. Yo le' +
          ' doy las gracias a Resuelve tu Deuda por la oportunidad de tener' +
          ' una vida tranquila. Ya no tengo deudas, soy la más feliz.'
      ];

      // Formulario
      let nameInput = document.getElementById('name');
      let emailInput = document.getElementById('email');
      let phoneInput = document.getElementById('phone');
      let cpInput = document.getElementById('cp');
      let debtInput = document.getElementById('debt');
      let findInput = document.getElementById('find');
      let tycInput = document.getElementById('tyc');
      let buttonForm = document.getElementById('button-form');
      let inputForm = ['name', 'email', 'phone', 'cp', 'debt', 'find'];

      let nameBoolean = false;
      let emailBoolean = false;
      let phoneBoolean = false;
      let cpBoolean = false;
      let debtBoolean = false;
      let tycBoolean = true;

      let nombre: string;
      let email: string;
      let phone: string;
      let cp: string;
      let find: string;
      let debt: string;
      let edo: string;

      // Landing source
      let referrer = document.referrer;
      let landing = window.location.href;
      let pathArray = window.location.pathname.split('/');
      let initialSourcePath = pathArray[1].toLowerCase();

      // Opciones input select
      let optionsGral = ['Televisión', 'Radio', 'Revistas',
        'Centro comercial o restaurante', 'Recomendación de un conocido'];
      let optionsTV = ['Televisa', 'TvAzteca', 'Foro TV', 'ADN 40'];
      let optionsMall = ['Perisur', 'Aeropuerto Terminal 1',
        'Aeropuerto Terminal 2', 'Parque Interlomas', 'Casa de Toño',
        'Galerías Monterrey'];
      let option = document.createElement('option');

      // Parámetros y cookies
      let gcid: string;
      let getCookie = (cname:string) => {
        let namec = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(namec) == 0) {
            return c.substring(namec.length, c.length);
          }
        }
        return '';
      }

      let getUrlParameter = function getUrlParameter(sParam: any) {
        let sPageURL = decodeURIComponent(window.location.search.substring(1));
        if (sPageURL === undefined || sPageURL === '' || sPageURL === null) {
          sPageURL = window.location.href.substr(window.location.href.indexOf('?') + 1)
        }

        let sURLVariables = sPageURL.split('&');
        let sParameterName: any;

        for (i = 0; i < sURLVariables.length; i++) {
          sParameterName = sURLVariables[i].split('=');
          if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
          }
        }
      };

      let source = getUrlParameter('utm_source');
      let campaignid = getUrlParameter('campaignid');
      let kenshooId = getUrlParameter('utm_id');
      let gcidfinal = getCookie('_ga');
      let actualStory: string = getUrlParameter('stories');;

      /* Console
      ---------------------------------------------------------------------------------- */
      console.log('%c%s', 'color: #FFFFFF; background: #007DA4; padding:8px 20px; line-height:25px; '
        + 'text-align: center;', 'Made by:');
      console.log('%c%s', 'color: #FFFFFF; background: #003764; padding:8px 6px; line-height:25px; '
        + 'text-align: center;', 'https://resuelve.mx');

      /* Show | Hide Home
      ---------------------------------------------------------------------------------- */
      // Función que limpia el formulario
      let clearForm = () => {
        for(var i=0; i<=5; i++) {
          (<HTMLInputElement> document.getElementById(inputForm[i])).value = '';
          (<HTMLInputElement> 
            document.getElementById(inputForm[i])).style.border = '1px solid rgba(115, 115, 115, 0.3)';
          (<HTMLInputElement> document.getElementById('alert-' + inputForm[i])).innerHTML = '';
        }
        nombre = '';
        email = '';
        phone = '';
        cp = '';
        find = '';
        debt = '';
      }

      // Muestra historias
      let dontShowHome = () => {
        storiesBoolean = true;
        TweenMax.from(document.getElementById('wrapper-head-stories'), 0.5, {
          delay: 0.7,
          autoAlpha: 0,
          ease: Power2.easeOut
        });
        TweenMax.from(document.getElementById('wrapper-video-stories'), 0.5, {
          delay: 1,
          autoAlpha: 0,
          ease: Power2.easeOut
        });
        TweenMax.from(document.getElementById('wrapper-story'), 0.5, {
          delay: 1.3,
          autoAlpha: 0,
          ease: Power2.easeOut
        });

        document.getElementById('wrapper-heroe').style.display = 'none';
        document.getElementById('wrapper-stories').style.display = 'none';
        document.getElementById('wrapper-banner').style.display = 'none';
        document.getElementById('wrapper-funciona').style.display = 'none';
        document.getElementById('wrapper-requisitos').style.display = 'none';
        document.getElementById('wrapper-somos').style.display = 'none';
        document.getElementById('wrapper-head-stories').style.display = 'block';
        document.getElementById('wrapper-video-stories').style.display = 'block';
        document.getElementById('wrapper-story').style.display = 'block';
        
        window.scrollTo(0, 0);
        clearForm();
      }

      // Muestra Home
      let showHome = () => {
        actualStory = 'HOME'
        storiesBoolean = false;
        window.scrollTo(0, 0);
        
        TweenMax.from(document.getElementById('wrapper-heroe'), 0.3, {
          delay: 0.6,
          autoAlpha: 0,
          ease: Power2.easeOut
        });
        TweenMax.from(document.getElementById('wrapper-stories'), 0.3, {
          delay: 0.8,
          autoAlpha: 0,
          ease: Power2.easeOut
        });
        TweenMax.from(document.getElementById('wrapper-banner'), 0.3, {
          delay: 1,
          autoAlpha: 0,
          ease: Power2.easeOut
        });
        TweenMax.from(document.getElementById('wrapper-funciona'), 0.3, {
          delay: 1.2,
          autoAlpha: 0,
          ease: Power2.easeOut
        });
        TweenMax.from(document.getElementById('wrapper-requisitos'), 0.3, {
          delay: 1.4,
          autoAlpha: 0,
          ease: Power2.easeOut
        });
        TweenMax.from(document.getElementById('wrapper-somos'), 0.3, {
          delay: 1.6,
          autoAlpha: 0,
          ease: Power2.easeOut
        });
        document.getElementById('wrapper-head-stories').style.display = 'none';
        document.getElementById('wrapper-video-stories').style.display = 'none';
        document.getElementById('wrapper-story').style.display = 'none';
        document.getElementById('wrapper-heroe').style.display = 'block';
        document.getElementById('wrapper-stories').style.display = 'block';
        document.getElementById('wrapper-banner').style.display = 'block';
        document.getElementById('wrapper-funciona').style.display = 'block';
        document.getElementById('wrapper-requisitos').style.display = 'block';
        document.getElementById('wrapper-somos').style.display = 'block';
        
        window.addEventListener('scroll', scrollPage);
        clearForm();
      }

      /* Navegación
      ---------------------------------------------------------------------------------- */
      // Menú Hamburger
      let showMenuMobile = () => {
        TweenMax.from(contentMenuMobile, 0.5, {
          scaleY: 0.01,
          transformOrigin: '50% top',
          ease: Expo.easeOut
        });
        for (i = 1; i <= 5; i++) {
          TweenMax.from(document.getElementById('item-menu-' + i), 0.4, {
            delay: i * 0.2,
            x: 300,
            autoAlpha: 0,
            ease: Power2.easeOut
          })
        }
      }

      // Submenú historias Hamburger
      let showSubmenuMobile = () => {
        for (i = 1; i <= 7; i++) {
          document.getElementById('item-submenu-' + i).style.display = 'block';
          TweenMax.from(document.getElementById('item-submenu-' + i), 0.3, {
            delay: i * 0.15,
            autoAlpha: 0,
            x: 100,
            ease: Power2.easeOut
          });
        } 
      }

      let closeMenuEvent = () => {
        menuMobile.style.display = 'none';
        closeMenu.style.display = 'none';
        iconMenu.style.display = 'block';
        phoneMobile.style.display = 'block';
        submenuMobile.style.display = 'none';
        submenuMobileBoolean = false;
      }

      let clickIconMenu = (event: any) => {
        iconMenu.style.display = 'none';
        phoneMobile.style.display = 'none';
        menuMobile.style.display = 'block';
        closeMenu.style.display = 'block';
        showMenuMobile();
      }

      let clickCloseMenu = (event: any) => {
        closeMenuEvent();
      }

      // Función para para scrollear ventana
      let scrollWindow = (pos: number) => {
        if (User('iOS') !== null) {
          TweenMax.to(document.getElementsByClassName('wrapper-section'), 1, {
            autoAlpha: 1,
            ease: Expo.easeOut
          });
          window.scrollTo(0, pos);
        } else {
          TweenMax.to(window, 1, {
            scrollTo:{
              y: pos
            },
            ease: Power2.easeOut
          });
        }
        closeMenuEvent();
      }

      // Función scroll al form
      let scrollForm = () => {
        formPos = document.getElementById('wrapper-form').offsetTop - 50;
        scrollWindow(formPos);
      }

      let scrollHome = (pos: number) => {
        switch(pos.toString()) {
          case '1':
            elementPos = document.getElementById('wrapper-stories').offsetTop - diffNav;
            scrollWindow(elementPos);
            break;
          case '2':
            elementPos = document.getElementById('wrapper-funciona').offsetTop - diffNav;
            scrollWindow(elementPos);
            break;
          case '3':
            elementPos = document.getElementById('wrapper-requisitos').offsetTop - diffNav;
            scrollWindow(elementPos);
            break;
          case '4':
            elementPos = document.getElementById('wrapper-somos').offsetTop - diffNav;
            scrollWindow(elementPos);
            break;
          case '5':
            elementPos = document.getElementById('wrapper-form').offsetTop - diffNav;
            scrollWindow(elementPos);
            break;
        }
      }

      // Click botones menú
      let clickItemMenu = (event: any) => {
        let elementId =  event.target.id;
        let elementIndex: number;

        diffNav = (widthScreen >= 996) ? 56 : 50;
        elementIndex = elementId.substring((elementId.length - 1), elementId.length);

        if (event.target.id === 'text-reinforcement') {
          showHome();
          if (User('iOS') !== null) {
            scrollHome(2);
          } else {
            TweenMax.delayedCall(1, scrollHome, [2])
          }
          return false;
        }

        // Solo para desktop menu hamburger
        if (widthScreen < 996) {
          posStr = event.target.id.substr(13, 14);
          switch(elementId) {
            case 'item-submenu-1':
              actualStory = 'rodolfo';
              openStory(actualStory, (posStr - 1));
              return false;
            case 'item-submenu-2':
              actualStory = 'maria';
              openStory(actualStory, (posStr - 1));
              return false;
            case 'item-submenu-3':
              actualStory = 'araceli';
              openStory(actualStory, (posStr - 1));
              return false;
            case 'item-submenu-4':
              actualStory = 'fermin';
              openStory(actualStory, (posStr - 1));
              return false;
            case 'item-submenu-5':
              actualStory = 'benjamin';
              openStory(actualStory, (posStr - 1));
              return false;
            case 'item-submenu-6':
              actualStory = 'lourdes';
              openStory(actualStory, (posStr - 1));
              return false;
            case 'item-submenu-7':
              actualStory = 'guillermina';
              openStory(actualStory, (posStr - 1));
              return false;
          }
        }

        if (!storiesBoolean) {
          // Menú en Home
          switch (elementId) {
            case 'item-menu-1':
              if (!submenuMobileBoolean) {
                submenuMobileBoolean = true;
                showSubmenuMobile();
                submenuMobile.style.display = 'block';
              } else {
                submenuMobileBoolean = false;
                submenuMobile.style.display = 'none';
              }
              break;
            case 'logo-rtd':
              elementPos = 0
              scrollWindow(elementPos);
              break;
            default:
              scrollHome(elementIndex);
              break;
          }
        } else {
          // Menú en Historias
          switch (elementId) {
            case 'item-menu-1':
              if (!submenuMobileBoolean) {
                submenuMobileBoolean = true;
                showSubmenuMobile();
                submenuMobile.style.display = 'block';
              } else {
                submenuMobileBoolean = false;
                submenuMobile.style.display = 'none';
              }
              break;
            case 'logo-rtd':
              elementPos = 0;
              scrollWindow(elementPos);
              break;
            case 'item-menu-5':
            case 'item-menu-desktop-5':
              elementPos = document.getElementById('wrapper-form').offsetTop - diffNav;
              scrollWindow(elementPos);
              break;
            default:
              showHome();
              if (User('iOS') !== null) {
                scrollHome(elementIndex);
              } else {
                TweenMax.delayedCall(1, scrollHome, [elementIndex])
              }
              break;
          }
        }
      }

      // Submenú mobile
      let clickItemSubmenu = (event: any) => {
        posStr = event.target.id.substr(13, 14);
        switch (event.target.id) {
          case 'item-submenu-1':
            actualStory = 'rodolfo';
            break;
          case 'item-submenu-2':
            actualStory = 'maria';
            break;
          case 'item-submenu-3':
            actualStory = 'araceli';
            break;
          case 'item-submenu-4':
            actualStory = 'fermin';
            break;
          case 'item-submenu-5':
            actualStory = 'benjamin';
            break;
          case 'item-submenu-6':
            actualStory = 'lourdes';
            break;
          case 'item-submenu-7':
            actualStory = 'guillermina';
            break;
        }
        openStory(actualStory, (posStr - 1));
      }

      // Cambios estilos en Menús
      let stylesDefaultMenu = (pos: number) => {
        for  (i = 1; i <= 4; i++) {
          document.getElementById('item-menu-desktop-' + i).style.color = '#737373';
          document.getElementById('item-menu-' + i).style.color = '#737373';
        }
        if (pos !== 0) {
          document.getElementById('item-menu-desktop-' + pos).style.color = '#77BC1F';
          document.getElementById('item-menu-' + pos).style.color = '#77BC1F';
        }
      }

      // Posición de la página al scroll
      let scrollPage = () => {
        let doc = document.documentElement;
        let posTop = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
        
        historiasPos = document.getElementById('wrapper-stories').offsetTop - 56;
        funcionaPos = document.getElementById('wrapper-funciona').offsetTop - 56;
        requisitosPos = document.getElementById('wrapper-requisitos').offsetTop - 56;
        somosPos = document.getElementById('wrapper-somos').offsetTop - 56;
        formPos = document.getElementById('wrapper-form').offsetTop - 56;

        if (!storiesBoolean) {
          if (posTop <  historiasPos) {
            posScroll = 0;
          } else if (posTop >=  historiasPos && posTop < funcionaPos) {
            posScroll = 1;
          } else if (posTop >=  funcionaPos && posTop < requisitosPos) {
            posScroll = 2;
          } else if (posTop >=  requisitosPos && posTop < somosPos) {
            posScroll = 3;
          } else if (posTop >=  somosPos && posTop < formPos) {
            posScroll = 4;
          } else if (posTop >=  formPos) {
            posScroll = 5;
          }
        }
        stylesDefaultMenu(posScroll);
      }
      
      window.addEventListener('scroll', scrollPage);

      /* Historias
      ---------------------------------------------------------------------------------- */
      // Posición de la página al scroll en Historias
      let scrollStory = (event: any) => {
        diffNav = (widthScreen >= 996) ? 56 : 50;
        elementPos = document.getElementById('wrapper-video-stories').offsetTop - diffNav;

        scrollWindow(elementPos);
      }

      // Muetsra Historia
      let openStory = (story: string, posStory: number) => {
        dontShowHome();
        imageStories.style.backgroundImage = 'url("assets/img/big_' + story + '.png")';
        titleStories.innerHTML = stories[posStory];
        subtitleStories.innerHTML = 'MOTIVO DE DEUDA:<br>' + subtitleStoriesContent[posStory];
        textStories.innerHTML = textStoriesContent[posStory];
        reinforcementStories.innerHTML = reinforcementStoriesContent[posStory];
        titleVideo.innerHTML = titleVideoContent[posStory];
        textVideo.innerHTML = textVideoContent[posStory];
        (<HTMLImageElement> frontVideo).src = 'assets/img/video_' + story + '.png';
        frontVideo.style.position = 'absolute';
        frontVideo.style.top = '0px';
        quote.innerHTML = quoteContent[posStory];

        if (widthScreen >= 996) {
          wrapperStories.style.backgroundImage = 'url("assets/img/big_video_' + story + '.png")';
        } else {
          wrapperStories.style.backgroundImage = 'url("assets/img/small_video_' + story + '.png")';
        }

        for (i = 2; i <= 4; i ++) {
          document.getElementById('item-menu-' + i).style.color = '#737373';
          document.getElementById('item-menu-desktop-' + i).style.color = '#737373';
        }
        document.getElementById('item-menu-1').style.color = '#77BC1F';
        document.getElementById('item-menu-desktop-' + i).style.color = '#77BC1F';

        addEventClass('click', 'see-more-story', scrollStory);

        if (actualStory !== undefined) {
          closeMenuEvent();
          window.removeEventListener('scroll', scrollPage);
        }
      }
      if (actualStory !== undefined) {
        switch(actualStory) {
          case 'rodolfo':
            posStr = 0;
            break;
          case 'maria':
            posStr = 1;
            break;
          case 'araceli':
            posStr = 2;
            break;
          case 'fermin':
            posStr = 3;
            break;
          case 'benjamin':
            posStr = 4;
            break;
          case 'lourdes':
            posStr = 5;
            break;
          case 'guillermina':
            posStr = 6;
            break;          
        }
        openStory(actualStory, posStr);
      }

      /* Home
      ---------------------------------------------------------------------------------- */
      if (document.getElementById('wrapper-congratulations') === null &&
        getUrlParameter('stories') === undefined) {
        showHome();
      }

      if (document.getElementById('wrapper-heroe') !== null) {
        // Background Heroe
        let videoScript = document.createElement('script');

        if (User('any') === null) {
          /* Inserta video [Desktop]
          ----------------------------------------------- */
          videoScript.type = 'text/javascript';
          videoScript.src = 'assets/js/video.js';

          let head = document.getElementsByTagName('head')[0];
          document.getElementsByTagName('head')[0].appendChild(videoScript);

          // Botón mute
          document.getElementById('content-hero').innerHTML += '<div id="button-video">' +
            '<img id="icon-mute" src="assets/img/mute.svg" alt="Mute"></div>'
        } else {
          /* Inserta script para videos mobile */
          videoScript.type = 'text/javascript';
          videoScript.src = 'assets/js/video_mobile.js';

          let head = document.getElementsByTagName('head')[0];
          document.getElementsByTagName('head')[0].appendChild(videoScript);

          /*Inserta imágenes [Mobile]
          ----------------------------------------------- */
          testimony1.id = 'image-1';
          testimony1.className = 'image-bg'
          testimony2.id = 'image-2';
          testimony2.className = 'image-bg'
          testimony3.id = 'image-3';
          testimony3.className = 'image-bg'
          testimony4.id = 'image-4';
          testimony4.className = 'image-bg'
          testimony5.id = 'image-5';
          testimony5.className = 'image-bg'
          testimony6.id = 'image-6';
          testimony6.className = 'image-bg'
          testimony7.id = 'image-7';
          testimony7.className = 'image-bg'
          frontHeroe.id = 'front-heroe';

          containerImageHero.appendChild(testimony1);
          containerImageHero.appendChild(testimony2);
          containerImageHero.appendChild(testimony3);
          containerImageHero.appendChild(testimony4);
          containerImageHero.appendChild(testimony5);
          containerImageHero.appendChild(testimony6);
          containerImageHero.appendChild(testimony7);
          containerImageHero.appendChild(frontHeroe);

          for (i = 1; i <= document.getElementsByClassName('image-bg').length; i++) {
            document.getElementById('image-' + i).style.backgroundImage = 'url("assets/img/big_' + 
              images[i - 1] + '.png")';
          }
          for (i = 2; i <= 7; i++) {
            TweenMax.to(document.getElementById('image-' + i), 0, {
              autoAlpha: 0
            });
          }

          // Animación imágenes
          let animationBg = () => {
            for (i = 1; i <= 7; i++) {
              TweenMax.to(document.getElementById('image-' + i), 0.5, {
                autoAlpha: 0,
                ease: Expo.easeOut
              });
            };
            TweenMax.to(document.getElementById('image-' + countImg), 1, {
              autoAlpha: 1,
              ease: Power2.easeOut
            });

            if (countImg < 7) {
              countImg++;
            } else {
              countImg = 1;
            }
            TweenMax.delayedCall(2, animationBg);
          };

          TweenMax.delayedCall(2, animationBg);

          // Cambios estilo en textos
          document.getElementById('see-more').style.color = '#003764';
          document.getElementById('hero-title').style.color = '#62A70F';
          document.getElementById('hero-subtitle').style.color = '#62A70F';
          document.getElementById('hero-text').style.color = '#4A4A4A';
        }
      }

      // Ver más | Conoce el programa
      let scrollFunciona = (event: any) => {
          widthScreen = window.innerWidth; 
          diffNav = (widthScreen >= 996) ? 56 : 50;
          let elementCont = (event.target.id.substr(5) === 'see') 
            ? document.getElementById('wrapper-stories') : document.getElementById('wrapper-funciona')

          let posContent = elementCont.offsetTop - diffNav;
          scrollWindow(posContent);
        }
      addEventClass('click', 'scroll-program', scrollFunciona);

      /* Slider
      ----------------------------------------------- */
      if (document.getElementById('wrapper-stories') !== null) {
        
        // Cambio de personaje
        let changeImage = (img: number) => {
          for (i = 1; i <= totalImages.length; i++) {
            TweenMax.to(document.getElementById('item-photo-' + i), 0.5, {
              autoAlpha: 0,
              ease: Expo.easeOut
            });
            document.getElementById('item-photo-' + i).style.display = 'none';
          }

          TweenMax.to(document.getElementById('item-photo-' + actualImage), 0.5, {
            delay: 0.35,
            autoAlpha: 1,
            ease: Power2.easeOut
          });
          document.getElementById('item-photo-' + actualImage).style.display = 'block';
          
          timerImageSlider();
        }

        // Personaje adelante
        let plusActualImage = () => {
          if (actualImage < totalImages.length) {
            actualImage++;
          } else {
            actualImage = 1;
          }
          changeImage(actualImage);
        }

        // Personaje atrás
        let minusActualImage = () => {
          if (actualImage > 1) {
            actualImage--;
          } else {
            actualImage = totalImages.length;
          }
          changeImage(actualImage);
        }

        // Drag slider
        let slider = Draggable.create('#container-images', {
          type: 'x',
          bounds: '#wrapper-images',
          edgeResistance: 0.5,
          throwProps: true,
          onDragStart: function(event: any) {
            posSliderInit = event.target.getBoundingClientRect().left
            TweenMax.killDelayedCallsTo(plusActualImage);
          },
          onDragEnd: function(event: any) {
            posSliderEnd = event.target.getBoundingClientRect().left;
            posSliderDiff = posSliderEnd - posSliderInit;
            TweenMax.killDelayedCallsTo(plusActualImage);

            TweenMax.to(containerImg, 0.3, {
              x: 0,
              ease: Expo.easeOut
            });
            if (posSliderDiff <= -30) {
              plusActualImage();
            } else if (posSliderDiff >= 30) {
              minusActualImage();
            }
          }
        });

        // Cambio de personaje al click en flechas
        let clickItemArrow = (event: any) => {
          TweenMax.killDelayedCallsTo(plusActualImage);
          let arrowClick = (event.target.id === 'arrow-left') ? minusActualImage() : plusActualImage();
        }

        // Timer para cambio de personaje
        let timerImageSlider = () => {
          TweenMax.delayedCall(5, plusActualImage);
        }

        // Habilita | Deshabilita slider
        if (widthScreen >= 996) {
          sliderActive = false;
          slider[0].disable();
        } else {
          sliderActive = true;
          timerImageSlider();
        }

        // Click en personaje
        let clickPhotoStory = (event: any) => {
          posStr = parseInt(event.target.id.substr(6)) - 1;
          switch (event.target.id) {
            case 'photo-1':
              actualStory = 'rodolfo';
              break;
            case 'photo-2':
              actualStory = 'maria';
              break;
            case 'photo-3':
              actualStory = 'araceli';
              break;
            case 'photo-4':
              actualStory = 'fermin';
              break;
            case 'photo-5':
              actualStory = 'benjamin';
              break;
            case 'photo-6':
              actualStory = 'lourdes';
              break;
            case 'photo-7':
              actualStory = 'guillermina';
              break;
          }
          let gotoStory = (initialSourcePath === 'gracias.html') ? 
            window.location.href = '/index.html?stories=' + actualStory : openStory(actualStory, posStr);
        }

        addEventClass('click', 'item-arrow', clickItemArrow);
        addEventClass('click', 'img-photo', clickPhotoStory);

        /* Resize
        ----------------------------------------------- */
        window.onresize = () => {
          widthScreen = window.innerWidth;
          heightScreen = window.innerHeight;

          // Habilita | Deshabilita slider al resize
          if (widthScreen >= 996) {
            sliderActive = false;
            TweenMax.killDelayedCallsTo(plusActualImage);
            slider[0].disable();
            for (i = 1; i <= totalImages.length; i++) {
              TweenMax.to(document.getElementById('item-photo-' + i), 0.3, {
                autoAlpha: 1,
                ease: Expo.easeOut
              });
              document.getElementById('item-photo-' + i).style.display = 'inline-block';
            }
          } else {
            for (i = 1; i <= totalImages.length; i++) {
              TweenMax.to(document.getElementById('item-photo-' + i), 0.3, {
                autoAlpha: 0,
                ease: Expo.easeOut
              });
              document.getElementById('item-photo-' + i).style.display = 'none';
            }
            document.getElementById('item-photo-' + actualImage).style.display = 'inline-block';
            TweenMax.to(document.getElementById('item-photo-' + actualImage), 1, {
              autoAlpha: 1
            });
            slider[0].enable();
            if (!sliderActive) {
              sliderActive = true;
              timerImageSlider();
            }
          }

          // Controla imágenes BG en historias
          let storyBg = (widthScreen >= 768) ? 
            wrapperStories.style.backgroundImage = 'url("assets/img/big_video_' + actualStory + '.png")' :
            wrapperStories.style.backgroundImage = 'url("assets/img/small_video_' + actualStory + '.png")';
        }
      }

      /* Formulario
      ---------------------------------------------------------------------------------- */
      if (document.getElementById('wrapper-form') !== null) {
        /* Opciones Select formulario
        ----------------------------------------------- */
        switch (initialSourcePath) {
          case 'deudas':
            for (i = 0; i < optionsMall.length; i++) {
              let options = new OptionsSelect(findInput, optionsMall[i]);
            }
            break;
          case 'tv':
            for (i = 0; i < optionsTV.length; i++) {
              let options = new OptionsSelect(findInput, optionsTV[i]);
            }
            break;
          case 'selecciones':
          case 'viva':
          case 'aire':
          case 'ciro':
          case 'oscar':
          case 'mariano':
          case 'muchedumbre':
          case 'martha':
          case 'radio':
          case 'fernanda':
          case 'imagen':
            document.getElementById('form-find').style.display = 'none';
            break;
          default:
            for (i = 0; i < optionsGral.length; i++) {
              let options = new OptionsSelect(findInput, optionsGral[i]);
            }  
            break;
        }
        console.log('::' + landing);
        if (landing === 'https://cienmilmaneras.mx/' || landing === 'https://cienmilmaneras.tv/' ||
          landing === 'https://cienmildeudas.com/' || landing === 'https://cienmildeudas.mx/' ||
          landing === 'https://cienmildeudas.tv/' || landing === 'https://cienmil.com/' ||
          landing === 'https://cienmil.mx/' || landing === 'https://cienmil.tv/') {
          document.getElementById('form-find').style.display = 'none';
        }

        // Formato $
        let formatNumber = {
          separador: ',',
          formatear: function(inp: string, num: string) {
            num += '';
            let splitStr = num.split('.');
            let splitLeft = splitStr[0];
            let splitRight = splitStr.length > 1;
            let regx = /(\d+)(\d{3})/;
            while (regx.test(splitLeft)) {
              splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
            }
            return this.symbol + splitLeft;
          },
            new: function(input: string, num: string, symbol: string) {
              this.symbol = symbol || '';
              return this.formatear(input, num);
          }
        };

        /* Validaciones Formulario
        ----------------------------------------------- */
        // Nombre
        let nameCheck = () => {
          if (nombre.length < 3) {
            nameBoolean = false;
            return false;
          }
          if (validateText('name', nombre, 'tu nombre completo')) {
            nameBoolean = true;
          } else {
            nameBoolean = false;
            return false;
          }
        }
        nameInput.oninput = (event: any) => {
          nombre = (<HTMLInputElement> nameInput).value;
          if (nombre.length >= 3) {
            nameCheck();
          } 
        }

        // Email
        let emailCheck = () => {
          if (validateEmail('email', email, 'tu correo electrónico')) {
            emailBoolean = true;
          } else {
            emailBoolean = false;
            return false;
          }
        }
        emailInput.oninput = (event: any) => {
          email = (<HTMLInputElement> emailInput).value;
          if (email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            emailCheck();
          }
        }

        // Teléfono
        let phoneCheck = () => {
          if (phone.length < 10) {
            document.getElementById('alert-phone').innerHTML = '<p class="text-warning" id="alert-msg">' +
                'Ingresa tu número telefónico a 10 dígitos.</p>';
              phoneInput.style.border = '1px solid #E60000';
            return false;
          }
          if (validateNumber('phone', phone, 'tu número telefónico', false, 10)) {
            phoneBoolean = true;
          } else {
            phoneBoolean = false;
            return false;
          }
        }
        phoneInput.oninput = (event: any) => {
          phone = (<HTMLInputElement> phoneInput).value;
          validateNumber('phone', phone, 'tu número telefónico', false, 0);
          if (phone.length === 10) {
            phoneCheck();
          }
        }

        // Código Postal
        let cpCheck = () => {
          if (cp.length < 5) {
            document.getElementById('alert-cp').innerHTML = '<p class="text-warning" id="alert-msg">' +
                'Ingresa tu código postal a 5 dígitos.</p>';
              cpInput.style.border = '1px solid #E60000';
            return false;
          }
          if (validateNumber('cp', cp, 'tu código postal', true, 5)) {
            cpBoolean = true;
          } else {
            cpBoolean = false;
            return false;
          }
        }
        cpInput.oninput = (event: any) => {
          cp = (<HTMLInputElement> cpInput).value;
          validateNumber('cp', cp, 'tu código postal', true, 0);
          if (cp.length === 5) {
            if (cp.substring(0, 2) === '00' || cp.substring(0, 2) === '17' ||
              cp.substring(0, 2) === '18' || cp.substring(0, 2) === '19') {
              document.getElementById('alert-cp').innerHTML = '<p class="text-warning" id="alert-msg">' +
                'Ingresa un C.P. válido.</p>';
              cpInput.style.border = '1px solid #E60000';
              return false;
            }
            cpCheck();
          }
        }

        // Total deuda
        let debtAlert = () => {
          debtBoolean = false;
          document.getElementById('alert-debt').innerHTML = '<p class="text-warning" id="alert-msg">' +
            'Por favor ingresa una cantidad igual o mayor a $35,000</p>';
          debtInput.style.border = '1px solid #E60000';
          return false;
        }
        let debtCheck = () => {
          if (debt === '' || debt === undefined || debt === null) {
            debtBoolean = false;
            debtAlert();
          }
          if (parseInt(debt) < 35000) {
            debtBoolean = false;
            debtAlert();
            return false;
          } else {
            debtBoolean = true;
          }
        }
        debtInput.onfocus = () => {
          if(debt === '' || debt === '0') {
            debt = '';
          } else if (debt !== undefined) {
            debt = Number (debt.replace(/[^0-9\.]+/g, '')).toString();
            (<HTMLInputElement> debtInput).value = parseInt(debt).toString();
          }
        }
        debtInput.oninput = (event: any) => {
          debt = (<HTMLInputElement> debtInput).value;
          validateNumber('debt', debt, 'una cantidad igual o mayor a $35,000', false, 0);
          if (debt.substring((debt.length - 1), debt.length) === '$') {
            debt = debt.slice(0, -1);
            (<HTMLInputElement> debtInput).value = debt;
          }

          if (debt.length >= 5) {
            if (parseInt(debt) < 35000) {
              debtBoolean = false;
              debtAlert();
            } else {
              debtCheck();
            }
          }
        }

        findInput.onchange = (event: any) => {
          find = (<HTMLInputElement> findInput).value
          findInput.style.color = '#4A4A4A';
          findInput.style.border = '1px solid #77BC1F';
        }

        // Checkbox
        let clickCheckBox = (event: any) => {
          let selector = <HTMLElement> document.querySelector('#alert-tyc');
          if (tycBoolean) {
            tycBoolean = false;
            selector.innerHTML = '<p class="text-warning" id="alert-msg">'
              + 'Debes aceptar Aviso de Privacidad y Términos y Condiciones</p>';
          } else {
            tycBoolean = true;
            selector.innerHTML = '';
          }
        }

        // Focus out inputs
        let inputOut = (event: any) => {
          nombre = (<HTMLInputElement> nameInput).value;
          email = (<HTMLInputElement> emailInput).value;
          phone = (<HTMLInputElement> phoneInput).value;
          cp = (<HTMLInputElement> cpInput).value;
          debt = (<HTMLInputElement> debtInput).value;

          switch (event.target.id) {
            case 'name':
              if (nombre === '' || nombre === null || nombre === undefined) {
                nameBoolean = false;
                document.getElementById('alert-name').innerHTML = '<p class="text-warning" id="alert-msg">' +
                  'Ingresa tu nombre completo.</p>';
                nameInput.style.border = '1px solid #E60000';
              } else if (nombre.length <= 3) {
                nameBoolean = false;
                document.getElementById('alert-name').innerHTML = '<p class="text-warning" id="alert-msg">' +
                  'Ingresa tu nombre completo.</p>';
                nameInput.style.border = '1px solid #E60000';
              } else {
                nameCheck();
              }
              break;
            case 'email':
              emailCheck();
              break;
            case 'phone':
              if (phone === '' || phone === null || phone === undefined) {
                phoneBoolean = false;
                document.getElementById('alert-phone').innerHTML = '<p class="text-warning" id="alert-msg">' +
                  'Ingresa tu número telefónico.</p>';
                phoneInput.style.border = '1px solid #E60000';
              } else if (phone.length < 10) {
                phoneBoolean = false;
                document.getElementById('alert-phone').innerHTML = '<p class="text-warning" id="alert-msg">' +
                  'Ingresa tu número telefónico a 10 dígitos.</p>';
                phoneInput.style.border = '1px solid #E60000';
              } else {
                phoneCheck();
              }
              break;
            case 'cp':
              if (cp === '' || cp === null || cp === undefined) {
                cpBoolean = false;
                document.getElementById('alert-cp').innerHTML = '<p class="text-warning" id="alert-msg">' +
                  'Ingresa tu C. P.</p>';
                cpInput.style.border = '1px solid #E60000';
              } else  if (cp.length < 5) {
                cpBoolean = false;
                document.getElementById('alert-cp').innerHTML = '<p class="text-warning" id="alert-msg">' +
                  'Ingresa tu C. P. a 5 dígitos.</p>';
                cpInput.style.border = '1px solid #E60000';
              } else if (cp.substring(0, 2) === '00' || cp.substring(0, 2) === '17' ||
                cp.substring(0, 2) === '18' || cp.substring(0, 2) === '19') {
                cpBoolean = false;
                document.getElementById('alert-cp').innerHTML = '<p class="text-warning" id="alert-msg">' +
                  'Ingresa un C.P. válido.</p>';
                cpInput.style.border = '1px solid #E60000';
              } else {
                cpCheck();
              }
              break;
            case 'debt':
              if (debt === '' || debt === null || debt === undefined) {
                debtBoolean = false;
                debtAlert();
              } else if (parseInt(debt) < 35000) {
                debtBoolean = false;
                (<HTMLInputElement> debtInput).value = formatNumber.new('debtInput', debt, '$');
                debtAlert();
              } else {
                debtCheck();
                (<HTMLInputElement> debtInput).value = formatNumber.new('debtInput', debt, '$');
              }
              break;
            default:
              console.log('default');
              break;
          }
        }

        /* Envío datos a Dynamo
        ---------------------------------------------------------------------------------- */
        let checks = () => {
          nombre = (<HTMLInputElement> nameInput).value;
          email = (<HTMLInputElement> emailInput).value;
          phone = (<HTMLInputElement> phoneInput).value;
          cp = (<HTMLInputElement> cpInput).value;
          debt = (<HTMLInputElement> debtInput).value;

          nameCheck();
          emailCheck();
          phoneCheck();
          cpCheck();
          debtCheck();
        }
        let clickButtonForm = (event: any) => {
          let debtInt = parseInt(debt.substr(0).replace(',', ''));
          event.preventDefault();
          checks();

          if (debtInt < 35000) {
            debtBoolean = false; 
          }
          
          if (nameBoolean && emailBoolean && phoneBoolean && cpBoolean && debtBoolean && tycBoolean) {
            /* Deshabilita el botón de enviar
            ---------------------------------------------- */
            (<HTMLInputElement> buttonForm).disabled = true;
            buttonForm.removeEventListener('click', clickButtonForm);
            buttonForm.classList.add('on-click');

            /* Setea valores en vars si las utms están vacías o son inexistentes
            ---------------------------------------------- */
            if (find === '' || find === undefined || find === null) {
              find = 'No especificó'
            }
            if (gcidfinal === '' || gcidfinal === undefined || gcidfinal === null) {
              gcid = 'empty';
            } else {
              gcid = gcidfinal.slice(6);
            }
            if (referrer === '' || referrer === undefined || referrer === null) {
              referrer = 'directo';
            }
            if (source === undefined || source === null || source === '') {
              source = 'directo | ' + actualStory;
            } else {
              source = source + ' | ' + actualStory;
            }
            if (campaignid === undefined || campaignid === null || campaignid === '') {
              campaignid = getUrlParameter('utm_campaign');
            }
            if (campaignid === undefined || campaignid === null || campaignid === '') {
              campaignid = getUrlParameter('cam');
            }
            if (campaignid === undefined || campaignid === null || campaignid === '') {
              campaignid = 'directo'
            }
            if (kenshooId === undefined || kenshooId === null || kenshooId === '') {
              kenshooId = 'empty';
            }
            edo = estado(cp);

            /* Se crea el objeto para ser enviado
            ---------------------------------------------- */
            let dataLead = JSON.stringify({
              'system_id': 1,

              'first_name': nombre,
              'email': email,
              'mobile': phone,
              'phone': phone,

              'postal_code': cp,
              'state': edo,

              'found_us': find,
              'come_from': referrer,
              'source': source,
              'landing': landing,
              'campaign': campaignid,

              'debt_amount': debtInt,
              
              'k_id': kenshooId,
              'gcid': gcid
            });

            /* Habilita el botón nuevamente si hay algún error
            ---------------------------------------------- */
            let enableButton = () => {
              (<HTMLInputElement> buttonForm).disabled = false;
              buttonForm.addEventListener('click', clickButtonForm);
              buttonForm.classList.remove('on-click');
            }

            /* Envío de datos
            ---------------------------------------------- */
            let sendData = () => {
              let selector = <HTMLElement> document.querySelector('#alert-button');

              if (xhrDynamo.readyState === 4) {
                let jsonResponse = JSON.parse(xhrDynamo.responseText);
                if (jsonResponse.code === 0) {
                  window.location.href = '/gracias.html?name=' + nombre;
                } else if (jsonResponse.code === -1) {
                  enableButton();
                  selector.innerHTML = '<p class="text-warning warning-out" id="alert-msg">'
                  + 'Ocurrió un error. Vuelve a intentarlo.</p>';
                } else if (jsonResponse.code === -2) {
                  enableButton();
                  selector.innerHTML = '<p class="text-warning warning-out" id="alert-msg">'
                  + 'Por favor llena todos los campos para continuar.</p>';
                } else if (jsonResponse.code === -3) {
                  enableButton();
                  selector.innerHTML = '<p class="text-warning warning-out" id="alert-msg">'
                  + 'Por favor llena todos los campos para continuar.</p>';
                } else if (jsonResponse.code === -4) {
                  enableButton();
                  selector.innerHTML = '<p class="text-warning warning-out" id="alert-msg">'
                  + 'No se pudo insertar el registro. Vuelve a intentarlo.<p>';
                }
              }
            }
            
            var xhrDynamo = new XMLHttpRequest();
            xhrDynamo.open("POST", "https://urmdamghr3.execute-api.us-east-1.amazonaws.com/prod/Leads/registerLead");
            xhrDynamo.setRequestHeader("content-type", "application/json");
            xhrDynamo.onreadystatechange = sendData;
            xhrDynamo.send(dataLead);
          } else {
            checks();
            if (!nameBoolean || !emailBoolean || !phoneBoolean || !cpBoolean || !debtBoolean) {
              scrollForm();
            }
            return false;
          }
        }

        buttonForm.addEventListener('click', clickButtonForm);
        iconMenu.addEventListener('click', clickIconMenu);
        closeMenu.addEventListener('click', clickCloseMenu);
        document.getElementById('logo-rtd').addEventListener('click', clickItemMenu);
        document.getElementById('reinforcement-video').addEventListener('click', clickItemMenu);
        addEventClass('click', 'item-menu', clickItemMenu);
        addEventClass('touchstart', 'item-submenu', clickItemSubmenu);
        addEventClass('click', 'button-cta', scrollForm);

        tycInput.addEventListener('click', clickCheckBox);
        addEventClass('blur', 'input-form', inputOut);
      }

      /* Thank you page
      ---------------------------------------------------------------------------------- */
      if (document.getElementById('wrapper-congratulations') !== null) {
        for (i = 1; i <= 5; i++) {
          document.getElementById('item-menu-desktop-' + i).style.display = 'none'
          document.getElementById('item-menu-' + i).style.display = 'none'
        }
        document.getElementById('icon-menu').style.display = 'none'

        document.getElementById('title-thankyou').innerHTML = '¡Felicidades ' + 
          getUrlParameter('name') + '!';

        let goHome = () => {
          window.location.href = './index.html';
        }

        document.getElementById('logo-rtd-typ').addEventListener('click', goHome);
      }

      /* Lazyload para las imágenes
      ---------------------------------------------------------------------------------- */
      window.addEventListener('load', function() {
        let allImages = document.getElementsByTagName('img');
        for (i=0; i<allImages.length; i++) {
          if (allImages[i].getAttribute('data-src')) {
            allImages[i].setAttribute('src', allImages[i].getAttribute('data-src'));
          }
        }
      }, false);

    });
  }
}

let main:Main = new Main();
