let isMobile = {
  Android: () => {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: () => {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: () => {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: () => {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: () => {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: () => {
    return (isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows());
  }
};

export function User(detect: string) {
  let isChrome: boolean;
  let os: string;
  let mobile: any;

  switch (detect) {
    case 'isChrome':
      isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
      return isChrome;
    case 'os':
      os = navigator.platform;
      return os;
    case 'Android':
      mobile = isMobile.Android();
      return mobile;
    case 'Blackberry':
      mobile = isMobile.BlackBerry();
      return mobile;
    case 'iOS':
      mobile = isMobile.iOS();
      return mobile;
    case 'Opera':
      mobile = isMobile.Opera();
      return mobile;
    case 'Windows':
      mobile = isMobile.Windows();
      return mobile;
    case 'any':
      mobile = isMobile.any();
      return mobile;
    default:
    return false;
    };
};
